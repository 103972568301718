import { gsap } from "gsap";
import barba from '@barba/core';
import Overlay from './classes/overlay.js';
import filteredList from './classes/filteredList.js';

const wrapper = document.querySelector('[data-barba="wrapper"]');

const overlayOpts = {
	links: '[data-fetch-content]',
	container: '#detail-container',
	gsap: gsap
};
const overlay = new Overlay(overlayOpts);

let filterContainers = document.querySelectorAll('[data-filter-container]');
if(filterContainers) {
	filterContainers.forEach(element => new filteredList({el: element}))
}





let sidebarBtn = document.querySelector('.sidebar-button');



barba.init({
	prefetchIgnore: false,
	transitions: [
		{
			name: 'slide-left',
			from: {
				namespace: 'home',
			},
			to: {
				namespace: 'varieties',
			},

			beforeLeave(data) {				
				wrapper.classList.add('barba-active');
				
				data.current.container.style.left = '0'
				data.next.container.style.left = '100%'
				data.next.container.style.opacity = '0';
				
				let almanac = document.querySelector('.almanac');
				almanac.style.position = 'absolute';
				almanac.style.top = window.scrollY + 'px';
			},


			enter: (data) => {
				return new Promise(resolve => {
					gsap.to(data.current.container, {
						duration: 0.5,
						left: '-100%',

						onStart: () => {
							sidebarBtn.querySelectorAll('a').forEach(element => {
								element.classList.toggle('is-active');
							})

							gsap.to(data.next.container, {
								duration: 0.5,
								left: 0,
								opacity: 1,

								onUpdate: () => {
									var contX = data.next.container.getBoundingClientRect().left;
									if(contX < window.innerWidth - 60) {
										sidebarBtn.style.right = 'auto';
										gsap.set(sidebarBtn, {left: contX})
									}
								},

								onComplete: () => {
									wrapper.classList.remove('barba-active');
									resolve();
								},
							});
						},
					});
				});
			},


			afterEnter(data) {
				wrapper.dataset.page = 'varieties';
				let almanac = document.querySelector('.almanac');
				almanac.removeAttribute('style');
				sidebarBtn.removeAttribute('style');
				sidebarBtn.classList.add('is-left');

				viewer();
				overlay.reset();

				filterContainers = document.querySelectorAll('[data-filter-container]');
				if(filterContainers) {
					filterContainers.forEach(element => new filteredList({el: element}));
				}
				
			}
		},




		{
			name: 'slide-right',
			from: {
				namespace: 'varieties',
			},
			to: {
				namespace: 'home',
			},

			beforeLeave(data) {
				wrapper.classList.add('barba-active');

				data.current.container.style.left = '0';
				data.next.container.style.left = '-100%';
				data.next.container.style.opacity = '0';
			},


			enter: (data) => {
				return new Promise(resolve => {
					let almanac = document.querySelector('.almanac');
					almanac.style.position = 'absolute';
					almanac.style.top = window.scrollY + 'px';
					

					gsap.to(data.current.container, {
						duration: 0.5,
						left: '100%',

						onStart: () => {
							sidebarBtn.querySelectorAll('a').forEach(element => {
								element.classList.toggle('is-active');
							})

							gsap.to(data.next.container, {
								duration: 0.5,
								left: 0,
								opacity: 1,

								onComplete: () => {
									wrapper.classList.remove('barba-active');
									resolve();
								},
							});
						},


						onUpdate: () => {
							var contX = data.current.container.getBoundingClientRect().left;
							
							if(contX < window.innerWidth - 120 && sidebarBtn.getBoundingClientRect().right <= window.innerWidth) {
								sidebarBtn.style.right = 'auto';
								gsap.set(sidebarBtn, {left: contX})
							} else {
								sidebarBtn.style.left = 'auto';
								gsap.set(sidebarBtn, {right: 0, left: 'auto'})
							}
						}
					});
				});
			},


			afterEnter(data) {
				wrapper.dataset.page = 'home';
				let almanac = document.querySelector('.almanac');
				almanac.removeAttribute('style');
				sidebarBtn.removeAttribute('style');
				sidebarBtn.classList.remove('is-left');

				slideshow();
				// scrollSidebarBtn();
				accordion();
			}
		},
	]
});


