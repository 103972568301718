export default class FilteredList {
    constructor(settings) {
        this.container = settings.el;
        this.form, this.inputs, this.items, this.selected;
        this.reset();
    }




    reset() {
        this.form = this.container.querySelector('form');
        this.inputs = this.form.querySelectorAll('input');
        this.items = this.container.querySelectorAll('[data-item]');
        
        let self = this;
        this.inputs.forEach(element => {
            element.addEventListener('input', function(e) {
                self.resetInputs(e)
                self.filterItems();
            })
        })

    }




    resetInputs(event) {
        this.inputs.forEach(element => {
            if(event.target.dataset.filter == 'all' && element.dataset.filter != 'all') element.checked = false
            if(event.target.dataset.filter != 'all' && element.dataset.filter == 'all') element.checked = false
        })

        this.selected = Array.from(this.inputs).filter(element => element.checked).map(element => element.dataset.filter)

        if(this.selected.length < 1) {
            this.form.querySelector('[data-filter="all"]').checked = true;
            this.selected.push('all');
        }

    }




    filterItems() {
        let self = this;

        this.items.forEach(item => {
            let itemCategories = item.dataset.category.split(';');

            if(self.selected[0] == 'all' || self.selected.some( element => itemCategories.indexOf(element) > -1) ) {
                if(item.classList.contains('is-hidden')) {
                    item.classList.remove('is-hidden')
                }
            } else {
                item.classList.add('is-hidden')

            }
        })
    }
}