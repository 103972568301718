export default class Overlay {
    constructor(settings) {
        this.linksSelector = settings.links;
        this.container = document.querySelector(settings.container);
        this.gsap = settings.gsap;

        if(this.container) {
            this.reset();
        }
    }




    reset() {
        let self = this;
        
        document.querySelectorAll(this.linksSelector).forEach(element => {
            
            element.addEventListener('click', function (e) {
                e.preventDefault();

                if(self.existsOnPage(element.dataset.id)) {
                    self.show(element.dataset.id);
                    self.open();
                    return true;
                }
                
                self.fetchNew(element.href)
                    .then(function(response) {
                        if(self.existsOnPage(element.dataset.id)) {
                            self.show(element.dataset.id)
                        } else {
                            return Promise.reject(new Error('Could not load'))
                        }
                    })
                    .then(response => self.open())
                    .catch(function(error) {
                        console.log(error)
                    });
            });
        });


        document.querySelectorAll('[detail-container-close]').forEach(element => {
            element.addEventListener('click', function(){
                self.close()
            });
        });
    }




    existsOnPage(slug) {
        return (this.container.querySelector(`[data-id="${slug}"]`)) ? true : false;
    }




    fetchNew(url) {
        let self = this;
        return fetch(url, {
            method: "GET",
            mode: "same-origin",
            credentials: "same-origin",
            headers: {
                "x-fetch": "true"
            }
        })
        .then(function (response) {
            if (response.status !== 200) {
                return Promise.reject(new Error('Not found: ' + response.status))
            } else {
                return response.text()
            }
        })
        .then(function (html) {
            self.container.insertAdjacentHTML('beforeend', html);
        })
        .catch(function(error) {
            console.log(error)
        });
    }




    show(id) {
        this.container.querySelectorAll('[data-id]').forEach(element => {
            if(element.dataset.id == id) {
                element.classList.remove('is-hidden')
            } else {
                element.classList.add('is-hidden')
            }
        });
    }




    open() {
        let left = (window.innerWidth > 750) ? 30 : 10;
        let body = document.querySelector('body');

        let activeDetail = this.container.querySelector('[data-id]:not(.is-hidden)');
        if(activeDetail) activeDetail.style.opacity = 0;

        body.classList.add('is-scroll-locked')
        this.gsap.to(this.container, {
            duration: 0.5,
            left: left+'%',

            onStart: () => {
                if(activeDetail) {
                    this.gsap.to(activeDetail, {
                        duration: 0.5,
                        opacity: 1,
                    });

                    window.dispatchEvent(new CustomEvent('overlay.open.start', {
                        detail: {
                            id: activeDetail.dataset.id
                        }
                    }));
                }
            },


            onComplete: () => {
                window.dispatchEvent(new CustomEvent('overlay.open.end', {
                    detail: {
                        id: activeDetail.dataset.id
                    }
                }));
            }
        })

        this.gsap.to(document.querySelector('main'), {
            duration: 0.5,
            left: (left - 100) + '%',
            onStart: () => {
                body.classList.add('barba-active');
            },  
        })
    }




    close() {
        let body = document.querySelector('body');

        body.classList.remove('is-scroll-locked')
        this.gsap.to(this.container, {duration: 0.5, left: '100%'})


        this.gsap.to(document.querySelector('main'), {
            duration: 0.5,
            left: 0,
            onComplete: () => {
                body.classList.remove('barba-active');
            },  
        })
    }
}